module.exports = [{
      plugin: require('/Users/samuelbassett/Documents/SamuelBassettWebDev/Grandaire/grandaire/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/samuelbassett/Documents/SamuelBassettWebDev/Grandaire/grandaire/src/intl","languages":["de","en"],"defaultLanguage":"de"},
    },{
      plugin: require('/Users/samuelbassett/Documents/SamuelBassettWebDev/Grandaire/grandaire/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/grandaire_favicon.svg"},
    },{
      plugin: require('/Users/samuelbassett/Documents/SamuelBassettWebDev/Grandaire/grandaire/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
